<template>
  <div>
    <v-btn
      v-if="allowedActions.canActivate"
      color="success"
      outlined
      small
      @click="activateTrainingCourse"
      :loading="activatingCourse"
    >
      <v-icon size="18" class="mr-1">mdi-check-all</v-icon> Activar curso
    </v-btn>

    <v-btn
      v-if="allowedActions.canClose"
      class="mr-2"
      color="info"
      outlined
      small
      @click="closeTrainingCourse"
      :loading="closingCourse"
    >
      <v-icon size="18" class="mr-1">mdi-close</v-icon> Cerrar curso
    </v-btn>

    <v-btn
      v-if="allowedActions.canGenerateCertificates"
      color="success"
      outlined
      small
      @click="generateCertificates"
      :loading="generatingCertificates"
    >
      <v-icon size="18" class="mr-1">mdi-certificate-outline</v-icon> Generar certificados
    </v-btn>

    <v-btn
      v-if="allowedActions.canRegenerateGroupalCertificates"
      color="success"
      outlined
      small
      @click="regenerateGroupalCertificates"
      :loading="generatingCertificates"
    >
      <v-icon size="18" class="mr-1">mdi-certificate-outline</v-icon> Regenerar cert. grupales
    </v-btn>
  </div>
</template>

<script>
export default {
  props: {
    allowedActions: { type: Object, required: true },
    notApprovedStudents: { type: Number || null },
  },
  data: () => ({
    closingCourse: null,
    activatingCourse: null,
    generatingCertificates: null,
    currentService: null,
  }),
  beforeMount() {
    this.currentService = this.$store.state.currentService;
  },
  methods: {
    async closeTrainingCourse() {
      try {
        this.closingCourse = true;
        await this.currentService.closeTrainingCourse(this.$route.params.id);
        this.$emit("courseClosed");
        this.$notifySuccess();
      } catch (e) {
        const message = e.response.data.message || "Error al cerrar el curso | " + e;
        this.$notifyError(undefined, message);
      } finally {
        this.closingCourse = false;
      }
    },
    async activateTrainingCourse() {
      try {
        this.activatingCourse = true;
        await this.currentService.activateTrainingCourse(this.$route.params.id);
        this.$emit("courseActivated");
        this.$notifySuccess();
      } catch (e) {
        this.$notifyError(undefined, "Error al activar el curso | " + e);
      } finally {
        this.activatingCourse = false;
      }
    },
    async generateCertificates() {
      let confirmed;
      if (this.notApprovedStudents) {
        const message = `Hay ${this.notApprovedStudents} alumnos no aprobados. ¿Quieres generar los certificados de todos modos?`;
        confirmed = await this.$confirm(message, {
          title: "Alumnos no aprobados",
        });
      }

      if (confirmed || !this.notApprovedStudents) {
        try {
          this.generatingCertificates = true;
          await this.currentService.generateCourseCertificates(this.$route.params.id);
          this.$emit("certificatesGenerated");
          this.$notifySuccess(
            undefined,
            "Certificados generados y pendientes de firma por el responsable"
          );
        } catch (e) {
          this.$notifyError(undefined, "Error al generar los certificados del curso | " + e);
        } finally {
          this.generatingCertificates = false;
        }
      }
    },
    async regenerateGroupalCertificates() {
      try {
        this.generatingCertificates = true;
        await this.currentService.regenerateCourseGroupalCertificates(this.$route.params.id);
        this.$notifySuccess(
          undefined,
          "Se han regenerado los certificados grupales y el documento excel DEA"
        );
      } catch (e) {
        this.$notifyError(
          undefined,
          "Error al regenerar los certificados grupales del curso | " + e
        );
      } finally {
        this.generatingCertificates = false;
      }
    },
  },
};
</script>

<style scoped></style>
